  <template>
  <div class="navbarmobileFix">
  <section class="contact-us-maindiv">
    <div class="container text-center">
      <h1 class="contact-usHeading">Have a query, feedback, or suggestion?</h1>
      <h2 class="contact-usText">Write your message with as much detail as possible.</h2>
      <h2 class="contact-usText">We will get back to you in 48 hours.</h2>
    </div>
  </section>
  <section class="banner-two  mt-5 mb-5" id="contact" >
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="shadow-sm p-3 mb-5 bg-white rounded">
            <div class="cards mb-5">
              <div class="card-body">
                <div class="form-header blue accent-1 text-center list-group shadow-box">
                  <div class="row">
                    <div class="col-12 " style="display:flex;">
                      <img src="~@/assets/img/message-icon.png" style="width: 10%;height:35px;" />
                      <h3 class="write-text"><i class="fas fa-envelope"></i> Write to us:</h3>
                    </div>
                  </div>
                </div>
                <form role="form" class="contact-form" style="margin-top: 6%">
                    <div class="form-group">
                      <label>Name*</label>
                      <input type="text" class="form-control" v-model="name" />
                    </div>
                    <div class="form-group">
                      <label>Mobile</label>
                      <input
                        type="text"
                        maxlength="10"
                        class="form-control"
                        v-model="mobile"
                        @input="mobilenumbervalidate"
                      />
                    </div>
                    <div class="form-group">
                      <label>Email*</label>
                      <input
                        type="email"
                        class="form-control"
                        v-model="email"
                        @input="handleEmailInput"
                      />
                    </div>
                    <div class="form-group">
                      <label>Message*</label>
                      <textarea
                      maxlength="50"
                        class="form-control"
                        rows="5"
                        v-model="message"
                      ></textarea>
                    </div>
                    <div class="form-group">Note: Message size should be a minimum of 50 characters.</div>
                  </form>
                  <button
                    style="float: right;"
                    type="submit"
                    class="btn btn-blue-color text-white"
                    @click="addQuery()"
                    :disabled="!isVerifyData" 
                    v-bind:class="[!isVerifyData ? 'disableBtn' : '']"
                  >
                    SUBMIT
                  </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mobileaddress">
          <div class="shadow-sm p-3 mb-5 bg-white rounded">
            <div class="cards">
              <div class="card-body">
                <div class="form-header blue accent-1 text-center list-group shadow-box">
                  <div class="row">
                    <div class="col-12" style="display:flex;">
                      <img src="~@/assets/img/location.png" style="width: 10%;height:35px;" />
                      <h3><i class="fas fa-map-marker-alt"></i> Find us:</h3>
                    </div>
                  </div>
                </div>
                <!-- <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.838007411241!2d80.21649731416905!3d13.045981216773134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266c1a3776ff3%3A0x7dc8413377bf3d33!2sBambeeQ+Solutions+Pvt+Ltd!5e0!3m2!1sen!2sin!4v1563183903720!5m2!1sen!2sin"
                  height="231" frameborder="0" style="width:100%;border:0;margin-top:6%;" allowfullscreen></iframe> -->
                <p class="find-us">Bamboo Wellness Solutions LLP<br />
                  NSIC STP Complex,<br />
                  No. 403 & 404, 3rd Floor Business Park,<br />
                  B-24, Guindy Industrial Estate, Ekkatuthangal,<br />
                  Chennai, Tamil Nadu 600032
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MobileNavbar></MobileNavbar>
  </section>

  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { defineComponent } from "vue";
import MobileNavbar from "../common-mobile-footer.vue"

export default defineComponent({
  components: {
    MobileNavbar,
  },
  props: {},
  data() {
    return {
      name: "",
      mobile: "",
      email: "",
      message: "",
    };
  },
  computed: {
    isVerifyData() {
  const nameRegex = /^[A-Za-z\s]+$/; // Only alphabets and spaces allowed for name
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex pattern
  const mobileRegex = /^[6-9]\d{9}$/; // Mobile number regex pattern

  const isNameValid = this.name && nameRegex.test(this.name); // Name is required and valid
  const isEmailValid = this.email && emailRegex.test(this.email); // Email is required and valid
  const isMobileValid = !this.mobile || mobileRegex.test(this.mobile); // Mobile is optional, if provided it should be valid
  const isMessageValid = this.message && this.message.length <= 50; // Message is required and less than or equal to 50 characters

  // Return true only if all conditions are met
  return isNameValid && isEmailValid && isMobileValid && isMessageValid;
}
  },
  methods: {
    handleEmailInput() {
      this.email = this.email.toLowerCase();
    },
    async addQuery() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      const payload = {
        name: this.name,
        mobile: this.mobile,
        email: this.email,
        description: this.message,
      };
      await axios
        .post(`${BASE_API_URL}/userquery/post`, payload)
        .then((response) => {
          if (response.status == 200) {
            Swal.fire({
              icon: "success",
              title: "Email send successfully",
              showConfirmButton: false,
              timer: 2000,
            });
            this.name='',
            this.mobile = '',
            this.email = '',
            this.message = ''
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }
        });
    },
  },
});
</script>

<style>
  .bottom-link-navbar{
    display: block !important;
  }
.contact-usHeading{
  margin-bottom: 3rem;
}
.container.text-center {
  padding-top: 30px;
  padding-bottom: 30px;
}

.contact-us-maindiv {
  padding-top: 3rem;
  padding-bottom: 4rem;
  margin-top: 3rem;
}

h3.write-text {
  margin-left: 7px;
}

.col-lg-6.cards.card-body.row.col-12 {
  background-color: #e5f4f5;
  padding-top: 13px;
  padding-bottom: 10px;
  border-radius: 6px;
}

p.find-us {
  color: #000;
  font-size: 17px;
  padding-top: 38px;
}

.list-group {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.shadow-box {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%), 0 2px 4px 0 rgb(0 0 0 / 12%) !important;
}

.form-header.blue.accent-1.list-group.shadow-box {
  margin-top: -58px;
  background: #e4f1f2;
}

.form-group {
  margin-bottom: 1rem;
}

div.form-group label {
  display: inline-block;
  margin-bottom: 0.5rem;
  /* margin-right: 500px; */
}

.btn.btn-blue-color.text-white.btn-submit {
  float: right;
}

.form-group label {
  height: 25px;
}
.shadow-sm.p-3.mb-5.bg-white.rounded {
  height: 95%;
}
@media screen and (max-width:991px) {
  .shadow-sm.p-3.mb-5.bg-white.rounded {
  height: 92%;
}
  .mobileaddress{
    margin-top: 20px;
  }
  /* .contact-usText{
    font-size: 20px;
  } */
  .contact-us-maindiv {
    margin-top: 1rem;
    padding-top:0px;
    padding-bottom: 0px;
  }
  .navbarmobileFix{
    margin-bottom: 7rem;
  }
}
@media screen and (max-width:768px) {
  .contact-usHeading{
    font-size: 28px;
  }
  .contact-usText{
    font-size: 24px;
  }
}
@media screen and (max-width:576px){
  .contact-usHeading{
    font-size: 24px;
    margin-bottom: 2rem;
  }
  .contact-usText{
    font-size: 20px;
    padding-bottom: 0.5rem;
  }
}

</style>